@import "../../bower/normalize-scss/_normalize";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
// $fa-font-path: "../../resources/bower/font-awesome/fonts";
@import "../../bower/font-awesome/scss/font-awesome";

$Montserrat-Regular: 'Montserrat-Regular';
$Montserrat-Light: 'Montserrat-Light';
$FashionFetish-Heavy: 'FashionFetish-Heavy';

$white: #ffffff;
$venetianred: #bd0026;
$caputmortuum: #501809;
$carmine: #ad001b;
$verylightgray: #cecece;
$darkgray: #5c5c5c;
$nero: #212121;
$whitesmoke: #f0f0f0;
$lightGray: #d8d8d8;
$gray: #cccccc; 

$easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275);

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../fonts/montserrat-regular-webfont.woff2') format('woff2'),
       url('../fonts/montserrat-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Light';
  src: url('../fonts/montserrat-light-webfont.woff2') format('woff2'),
       url('../fonts/montserrat-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FashionFetish-Heavy';
  src: url('../fonts/FashionFetishHeavy.eot?#iefix') format('embedded-opentype'),  
  		 url('../fonts/FashionFetishHeavy.woff') format('woff'), 
  		 url('../fonts/FashionFetishHeavy.ttf')  format('truetype'), 
  		 url('../fonts/FashionFetishHeavy.svg#FashionFetishHeavy') format('svg');
  font-weight: normal;
  font-style: normal;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
.show-mobile {
	display: none !important;
}
html, body {
	height: 100%;
	font-family: $Montserrat-Regular;
	font-size: 16px;
	color: $verylightgray;

}
body {
	overflow: hidden;
	margin: 0;
	padding: 0;
}
a, a:hover, a:focus {
	color: inherit;
	text-decoration: none;
	outline: none;
}
ul {
	padding-left: 0;
}
li {
	list-style: none;
}
/* ANIMATE LIQUID FILL */
.liquid {
   transform: translateX(100%);
   transform: translateY(100%);
   opacity: 0.8;
   // animation: fill 3.2s;
   // animation-fill-mode: forwards;
}

/*// @keyframes fill {
//    0%{
//       opacity: 0.5;
//       transform: translateY(100%);
//    }
//    100%{
//       transform: translateY(0);
//       opacity: 1;
//    }
// }*/

/* ANIMATE STEAM */
.steam {
   stroke: #F7F7F7;
   stroke-width: 1.5px;
   stroke-dasharray: 200;
   stroke-dashoffset: 200;
   // animation: steamAnimate 2.5s;
   // animation-delay: 3.3s;
   // animation-fill-mode: forwards;
}

/*// @keyframes steamAnimate{
// 	0%{
// 		stroke-dashoffset: 200;
// 	}
	  
// 	100%{
// 		stroke-dashoffset: 0;
// 	}
// }*/
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.pace .pace-progress {
	background: $venetianred;
	position: fixed;
	z-index: 999999;
	top: 0;
	right: 100%;
	width: 100%;
	height: 2px;
}
body.initial {
	.pace .pace-progress {
		background: transparent;
		position: fixed;
		z-index: 999999;
		// top: 50%;
		right: initial;
		left: 50%;
		// height: 3rem;
		width: 5rem;
	    display: flex;
	    justify-content: center;
	    align-items: center;
		-webkit-transform: translateX(-50%) !important;
		-ms-transform: translateX(-50%) !important;
		transform: translateX(-50%) !important;

		height: 400px;
    	top: 50%;

	  // transform: translate3d(0, 0, 0) !important;

	  // background: #ee3148;
	  // position: fixed;
	  // z-index: 999999;
	  // top: 0;
	  // right: 100%;
	  // width: 100%;
	  // height: 2px;
	}

	.pace .pace-progress:after {
	  display: block;
	  content: attr(data-progress-text);
	  font-size: 30px;
	  text-align: right;
	  color: white;
	}
}
#loader {
	position: fixed;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color:  white;
	z-index: 9999;
	svg {
		transform: translate(-50%, -50%);
		position: absolute;
		top: 50%;
		left: 50%;
	}
	// .bg {
	// 	fill: $verylightgray;
	// }
	// .steam {
	// 	stroke:#FFF;
	// 	fill: none;
	// 	stroke-width: 2px;
	// }
	// .cup {
	// 	fill:#FFF;
	// 	stroke:#FFF;
	// 	stroke-width:2;
	// 	stroke-miterlimit:10;
	// }
	// .liquid {
	// 	fill:#351F02;
	// 	stroke:#351F02;
	// 	stroke-miterlimit:10;
	// }
	// .sleeve {
	// 	fill:$venetianred;
	// }
	// .lid {
	// 	fill:$venetianred;
	// 	stroke:$venetianred;
	// 	stroke-width:2;
	// 	stroke-miterlimit:10;
	// }

	//new laoder

    .st0{
    	fill:#990000;
    }
    .st1{
    	enable-background:new;
    }
    .steam{
    	fill-rule:evenodd;
    	clip-rule:evenodd;
    	fill:none;
    	stroke:#FFFFFF;
    	stroke-width:3;
    	stroke-miterlimit:100;
    }
    .st3{
    	fill:#999999;
    }
    .st4{
    	fill:#663300;
    }
    .st5{
    	fill:#FFFFFF;
    }
    .st6{
    	fill:#F4DDB2;
    }
    .st7{
    	fill:#F4DDB3;
    }
    #steam1{
		stroke-dasharray:210;
		stroke-dashoffset:210;
    }
    #steam2{
		stroke-dasharray:210;
		stroke-dashoffset:-210px;
    }
}

.slick-slide {
	outline: none !important;
}


.cd-nav-trigger {
	float: right;
	width: 28px;
	height: 27px;
	position: relative;
	z-index: 999;
	padding: 0px !important;

	span {
		margin: -2px 0 0;
		position: absolute;
		top: 50%;
		width: 28px;
		height: 3px;
		background-color: $venetianred;
		// display: none;
		-webkit-transition-duration: 0s;
		-moz-transition-duration: 0s;
		transition-duration: 0s;
		-webkit-transition-delay: 0.2s;
		-moz-transition-delay: 0.2s;
		-o-transition-delay: 0.2s;
		-ms-transition-delay: 0.2s;
		transition-delay: 0.2s;

		&:before,&:after {
			position: absolute;
			content: '';
			width: 28px;
			height: 3px;
			background-color: $venetianred;
			display: block;
		}

		&:before {
			margin-top: -10px;
			-webkit-transition-property: margin, -webkit-transform;
			-webkit-transition-duration: .2s;
			-webkit-transition-delay: .2s, 0s;
			-moz-transition-property: margin, -moz-transform;
			-moz-transition-duration: .2s;
			-moz-transition-delay: .2s, 0s;
			transition-property: margin, transform;
			transition-duration: .2s;
			transition-delay: .2s, 0s;
		}

		&:after {
			margin-top: 10px;
			-webkit-transition-property: margin, -webkit-transform;
			-webkit-transition-duration: .2s;
			-webkit-transition-delay: .2s, 0s;
			-moz-transition-property: margin, -moz-transform;
			-moz-transition-duration: .2s;
			-moz-transition-delay: .2s, 0s;
			transition-property: margin, transform;
			transition-duration: .2s;
			transition-delay: .2s, 0s;
		}
	}

	&:hover {
		border: none
	}

	&.active {
		span {
			background-color: rgba(0,0,0,0);
			-webkit-transition-delay: 0.2s;
			-moz-transition-delay: 0.2s;
			-o-transition-delay: 0.2s;
			-ms-transition-delay: 0.2s;
			transition-delay: 0.2s;
			
			&:before {
				margin-top: 0;
				-webkit-transform: rotate(45deg);
				-webkit-transition-delay: 0s, .2s;
				-moz-transform: rotate(45deg);
				-moz-transition-delay: 0s, .2s;
				transform: rotate(45deg);
				transition-delay: 0s, .2s;
			}

			&:after {
				margin-top: 0;
				-webkit-transform: rotate(-45deg);
				-webkit-transition-delay: 0s, .2s;
				-moz-transform: rotate(-45deg);
				-moz-transition-delay: 0s, .2s;
				transform: rotate(-45deg);
				transition-delay: 0s, .2s
			}
		}
	}
}


.container{
	max-width: 1000px;
}

nav.navbar{
	border-radius: 0;
	background-color: $white;
	border: none;
	margin-bottom: 0;
	border-bottom: 9px solid $venetianred !important;

	.container{
		
		.navbar-header{
			.navbar-toggle {
				border: none;
				background-color: transparent;
				margin-top: 26px;

				&:hover,&:focus {
					background-color: transparent;
				}

				.icon-bar {
					background-color: $venetianred;
					width: 30px;
					height: 5px;
					border-radius: 5px;
					
					&+ .icon-bar {
						margin-top: 5px;
					}
				}
			}
			a.navbar-brand{
				padding: 22px 15px 24px;
				height: auto;
			}
		}

		.navbar-collapse{
			ul{
				margin-top: 32px;
	    		margin-bottom: 20px;

				li{
					text-transform: uppercase;
					padding: 0 15px;

					a{
						display: inline-block;
						font-size: 15px;
						font-weight: 600;
						letter-spacing: 2.2px;
						padding: 0 0 4px;
						color: $venetianred !important;
						background: none !important;
						position: relative;
						overflow: hidden;
						z-index: 20;

						&:before,
						&:after {
							content: '';
							position: absolute;
							bottom: 2px;
							left: 0; right: 0;
							height: 2px;
							background-color: $venetianred;
						}

						&:before {
							opacity: 0;
							-webkit-transform: translateY(- 4px);
							-ms-transform: translateY(- 4px);
							transform: translateY(- 4px);
						
							-webkit-transition: opacity 0s, -webkit-transform 0s $easeOutBack;
							transition: opacity 0s, -webkit-transform 0s $easeOutBack;
							-o-transition: transform 0s $easeOutBack, opacity 0s;
							transition: transform 0s $easeOutBack, opacity 0s;
							transition: transform 0s $easeOutBack, opacity 0s, -webkit-transform 0s $easeOutBack;
						}

						&:after {
							opacity: 0;
							-webkit-transform: translateY(2px);
							-ms-transform: translateY(2px);
							transform: translateY(2px);
							-webkit-transition: opacity 0.2s, -webkit-transform 0.2s $easeOutBack;
							transition: opacity 0.2s, -webkit-transform 0.2s $easeOutBack;
							-o-transition: transform 0.2s $easeOutBack, opacity 0.2s;
							transition: transform 0.2s $easeOutBack, opacity 0.2s;
							transition: transform 0.2s $easeOutBack, opacity 0.2s, -webkit-transform 0.2s $easeOutBack;
						}

						&:hover,
						&:focus {
							color: $venetianred;
						
							&:before,
							&:after {
								opacity: 1;
								-webkit-transform: translateY(0);
								-ms-transform: translateY(0);
								transform: translateY(0);
							}
							&:before {
								-webkit-transition: opacity 0.2s, -webkit-transform 0.2s $easeOutBack;
								transition: opacity 0.2s, -webkit-transform 0.2s $easeOutBack;
								-o-transition: transform 0.2s $easeOutBack, opacity 0.2s;
								transition: transform 0.2s $easeOutBack, opacity 0.2s;
								transition: transform 0.2s $easeOutBack, opacity 0.2s, -webkit-transform 0.2s $easeOutBack;
							}
							&:after {
								-webkit-transition: opacity 0s 0.2s, -webkit-transform 0s 0.2s $easeOutBack;
								transition: opacity 0s 0.2s, -webkit-transform 0s 0.2s $easeOutBack;
								-o-transition: transform 0s 0.2s $easeOutBack, opacity 0s 0.2s;
								transition: transform 0s 0.2s $easeOutBack, opacity 0s 0.2s;
								transition: transform 0s 0.2s $easeOutBack, opacity 0s 0.2s, -webkit-transform 0s 0.2s $easeOutBack;
							}
						}
					}

					ul.dropdown-menu{
						position: absolute;
						background: transparent;
						top: 0;
						right: 0;
						margin-top: 0px;
						padding: 45px 0;
						border-radius: 0;
						box-shadow: none;
						border: none;
						z-index: 10;

						/*width: 235px;*/

						li{
							padding: 16px 45px 16px 15px;
							background: $white;

							a{
								padding: 0 0 6px;
								display: inline;
							}
						}

						li:last-child{
							padding: 16px 45px 20px 15px;
						}
					}
				}

				li.dropdown:hover > ul.dropdown-menu{
					display: block;

					-moz-animation: fadeInDown .3s ease-in ;
					-webkit-animation: fadeInDown .3s ease-in ;
					animation:fadeInDown .3s ease-in ;
				}

				li.dropdown > a
				li.open > a{
					background: $white;
					color: $venetianred;
				}

				li:last-child{
					padding: 0 0 0 15px;
				}
			}
		}
	}
}

footer.footer{
	color: $white;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 600;
	letter-spacing: 2.2px;
	background: $nero;

	.container{
		
		.row{
			padding: 0 50px;
			margin: 45px 0 23px;
			
			ul{
				margin-bottom: 10px;

				li{
					display: inline-block;
					padding: 0 20px;	

					a:hover{
						opacity: 0.6;
					}
				}

				li:first-child{
					padding: 0 20px 0 5px;
				}

				li:last-child{
					padding: 0 10px 0 20px;
				}
			}


			.social-media-footer {
				margin-top: 10px;
				font-size: 16px;
				text-align: center;

				ul {
					li {
						display: inline-block !important; 
						margin-bottom: 0px !important;
					}
				} 
			}
		}
	}
}

.no-select {
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}

.no-pad{
	padding-left: 0;
	padding-right: 0;
}
section.social-media-container {
	background: $venetianred;
	position: fixed;
	right: 0px;
	top:50%;
	transform: translateY(-50%);
	z-index: 99;
	    -webkit-box-shadow: -1px 0px 5px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: -1px 0px 5px 0px rgba(0,0,0,0.25);
    box-shadow: -1px 0px 5px 0px rgba(0,0,0,0.25);

	.social-media-list {
		color: white;
		padding: 10px 0px;
		.social-media {
			text-align: center;
			font-size: 30px;
			padding: 10px 20px;

			a{
				transition: .2s;
				
				i{
					transition: .2s;
				}
			}

			a:hover i{
				transform: scale(1.3, 1.3);
			}
		}
	}
}

//slick
.slick-dots {
	bottom: 20px;
	li {
		margin: 0px;
	
		button {
			&:before {
				opacity: 0.15;
				font-size: 10px;
			}
		}

		&.slick-active,&:hover {
			button {
				&:before {
					opacity: 0.35;
				}
			}
		}
	}
}
section.page{
	.container{
		padding: 0;
	}

	.section{
		&.banner-section{
			font-family: $FashionFetish-Heavy;

			.banner-container{
				padding: 0;
				border-bottom: 20px solid $white;

				.banner{
					height: 438px;

					.banner-content{
						// display: flex;
						// height: 100%;
						// margin: 0;

						.image-holder{
							// overflow: hidden;
							padding: 0px;
							display: none;

							img{
								max-width: 100%;  
						    // float: right;
						    // margin: -120px 30px 0;
							}
						}
						// .text-container {
						// 	display: flex;
						// 	align-items: center;

						// 	.text-holder{
						// 		text-transform: uppercase;
						// 		text-align: center;
						// 		> :first-child(){
						// 			margin-bottom: 0px;
						// 		}
						// 		> :nth-child(2) {
						// 			margin: 0px;
						// 		}
						// 		h1{
						// 			color: $carmine;
						// 			font-size: 48.51px;
						// 			letter-spacing: 7.5px;
						// 		}
						// 		h2{
						// 			color: $caputmortuum;
						// 			font-size: 43.92px;
						// 			letter-spacing: 4px;
						// 		}
						// 		h3{
						// 			color: $caputmortuum;
						// 			font-size: 25.66px;
						// 			letter-spacing: 2.5px;
						// 		}
						// 	}
						// }
					}
				}
			}
			.slick-dotted.slick-slider {
				margin-bottom: 0px;
			}
		}

		&.grid-section{
			.grid-container{
				padding: 0 15px;
				border-bottom: 25px solid $venetianred;

				.grid-item{
					padding: 0;

					.grid-content-holder{
						position: relative;
						
						.overlay{
							background: rgba(0, 0, 0, 0.3);
							height: 290px;
							-webkit-transition: all 0.5s ease;
							-o-transition: all 0.5s ease;
							transition: all 0.5s ease;
						}

						.grid-content{
							position: absolute;
							width: 100%;
						  top: 50%;
						  -webkit-transform: translateY(-50%);
					    -ms-transform: translateY(-50%);
			        transform: translateY(-50%);

						  .grid-image-holder{
						  	height: 124px;
						  	position: relative;

						  	.grid-image{
						  		position: absolute;
									width: 100%;
								  bottom: 0;
						  		
							  	img{
							  		max-width: 100%;
							  	}
						  	}
						  }
							
							.grid-name{
								color: $white;
								text-transform: uppercase;
								font-weight: 600;
								margin-top: 20px;
								letter-spacing: 2.2px;
							}
						}
					}

					.grid-content-holder:hover{
						.overlay{
							background: rgba(189, 0, 38, 0.72);
						}
					}
				}
			}

			.grid-accordion {
				padding: 0px;
				background: $venetianred;
				margin-bottom: 0px;
				border: 0px;

				.panel {
					border: none;
					border-radius: 0px;
					background-color: transparent;
					box-shadow: none;
				}

				.card-header {
					letter-spacing: 5px;
					font-size: 26.4px;
					font-weight: 600;
					font-family: $Montserrat-Regular;
					color: white;
					text-align: center;
					text-transform: uppercase;
					opacity: 1;
					transition-delay: 5s;
					transition: 5s;

					.card-header-link{
						h3{
							letter-spacing: 5px;
							font-size: 26.4px;
							font-weight: 600;
							margin-top: 0;
							margin-bottom: 0;
							display: inline-block;
							cursor: pointer;
						}
					}
					
					&.fadeOut {
						opacity: 0;
						height: 0;
					}

					div {
						padding: 30px 10px;
					}
				}
				
				.grid-content-holder {
					.overlay{
						background: rgba(189,0,38,0.7) !important;
					}
				}
			}
		}

		&.mailing-list-section {
			.container{
				.row{
					margin: 0;

					.th-form{
						padding: 0;
					}
				}
			}

			.mailing-list-inner{
				max-width: 660px;
				padding: 0 15px;
				margin: 60px auto 75px;

				@media screen and (max-width: 767px) {
					margin: 30px auto 35px;
				} 

				h5{
					font-size: 28px;
					font-weight: 600;
					color: $venetianred;
					text-transform: uppercase;
					letter-spacing: 1px;

					@media screen and (max-width: 767px) {
						font-size: 22px;
					} 
				}

				.form-content{
					padding: 12px 0 32px;
					
					@media screen and (max-width: 767px) {
						padding: 12px 0 12px;
					} 

					input{
						height: 40px;
						border: 3px solid $venetianred;
						border-radius: 0;
						background: #f8f8fa;

						margin-right: 10px;
						display: inline-block;
						width: calc(100% - 160px);

						@media screen and (max-width: 500px) {
							width: 100%;
						} 
					}

					ul.parsley-errors-list{
						position: absolute;
						font-size: 12px;
						color: $venetianred;
						margin: 5px 0 0;

						@media screen and (max-width: 767px) {
							position: relative;
						}
					}

					input::placeholder { 
						color: #b3b3b3;
						font-size: 16px;
						font-family: $Montserrat-Light;
					}

					input::-webkit-input-placeholder { 
						color: #b3b3b3;
						font-size: 16px;
						font-family: $Montserrat-Light;
					}
					input::-moz-placeholder { 
						color: #b3b3b3;
						font-size: 16px;
						font-family: $Montserrat-Light;
					}
					input:-ms-input-placeholder { 
						color: #b3b3b3;
						font-size: 16px;
						font-family: $Montserrat-Light;
					}
					input:-moz-placeholder { 
						color: #b3b3b3;
						font-size: 16px;
						font-family: $Montserrat-Light;
					}

					button{
						background: $venetianred;
						font-size: 16px;
						font-family: $Montserrat-Light;
						color: $white;
						height: 40px;
						text-transform: uppercase;
						border-radius: 0;
						padding: 0 37px;
						
						display: inline-block;
						vertical-align: top;

						@media screen and (max-width: 500px) {
							width: 100%;
							margin-top: 10px;
						} 
					}
				}

				.disclaimer-holder{
					font-size: 10px;
					font-family: $Montserrat-Light;
					color: #a9a9a9;

					@media screen and (max-width: 843px) {
						margin-top: 20px;
					}

					@media screen and (max-width: 767px) {
						margin-top: 10px;
					}
				}
			}
		}

		&.slider-section {
			margin-bottom: 70px;
			.slider-container {
				.slider-content-container{
					padding: 100px 40px;
					.slider-content {
						display: flex;

						// > *{
						// 	flex: 1 1 1;
						// }

						.img-container {
							img {
								max-width: 100%;
							}
						}
						.content-wrapper {
							display: flex;
							align-items: center;

							.content {
								max-width: 500px;
								padding: 0px 25px;

								.title {
									color: $venetianred;
									font-family: $Montserrat-Regular;
									font-weight: 600;
									font-size: 33px;
									letter-spacing: 2.2px;
									text-transform: uppercase;
								}

								.description {
									color: black;
									font-size: 22px;
									font-family: $Montserrat-Light;
								}
							}
						}
					}
				}

				.banner-img {
					width: 100%;
					min-height: 348px;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					position: relative;

					.overlay {
						width: 100%;
						height: 100%;
						position: absolute;
						background-color: rgba(0,0,0,0.3);
						display: flex;
						align-items: center;
						justify-content: center;

						.title {
							font-family: $Montserrat-Regular;
							font-weight: 600;
							font-size: 33px;
							letter-spacing: 2.2px;
							text-transform: uppercase;
							color: white;
							padding: 10px;
							text-align: center;
						}
					}
				}
				.slider-holder {
					position: relative;

					.slider-prev,.slider-next {
						z-index: 2;
						font-size: 40px;
						font-family: 'Calibri';
						color: $venetianred;
						width: 40px;
						height: 40px;
						position: absolute;
						top: 50%;
						-webkit-transform: translateY(-50%);
						-ms-transform: translateY(-50%);
						transform: translateY(-50%);
						/*background: $lightGray;*/
						/*opacity: 0.3;*/
						/*border-radius: 100%;*/
						line-height: 0px;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						transition: .5s;

						&:hover {
							opacity: 1;
						}
					}

					.slider-prev {
						left: 40px;
					}

					.slider-next {
						right: 40px;
					}

				}

				.slider-holder-custom{
					.slider-prev,.slider-next {
						transform: none;
						-webkit-transform: none;
						-ms-transform: none;
						/*border-radius: 0;
						background: none;
						opacity: 1;*/
						top: 0;
					}
					.slider-prev{
						left: 20px;
					}
					.slider-next{
						right: 20px;
					}
					.slick-list{
				    padding: 30px 50px 0 !important;
   		 			margin-top: 30px;
					}
				}
				.slides-container,
				.slides-container-not {
					.slides {
						max-width: 266px;
						cursor: pointer;

						.img-container {
							img {
								max-width: 100%;
							}
						}

						.content {
							font-family: $Montserrat-Regular;
							font-size: 19px;
							color: $darkgray;
							text-align: center;
							text-transform: uppercase;
							line-height: 23.5px;
							font-weight: 600;

							width: 90%;
							margin: 0 auto;
						}
					}
				}

				.slides-container-not {
					text-align: center;

					.slides {
						display: inline-block;
						vertical-align: top;
					}
				}
			}
		}

		&.history-section{
			.container{
				background: $whitesmoke;
			}

			.timeline-holder{
				font-family: $Montserrat-Light;
				font-size: 16px;
				color: $darkgray;
				margin-top: -105px;

				h2{
					font-family: $Montserrat-Regular;
					font-weight: 600;
					font-size: 22px;
					letter-spacing: 4px;
					color: $white;
					text-align: center;
					margin: 0;
					background-color: $venetianred;
			    	display: inline-block;
					padding: 10px 20px;
				}

				.timeline{
					position: relative;
					margin: 0 90px;
					padding-top: 50px;
					overflow: hidden;

					&:before{
						content: '';
						position: absolute;
						height: 100%;
						width: 3px;
						background: $verylightgray;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						margin: 0 auto;
					}

					.timeline-point{
						position: relative;
						width: 100%;
						margin-bottom: 35px;

						.timeline-icon{
							position: absolute;
							top: 45%;
							height: 20px;
							width: 20px;
							left: 0;
							right: 0;
							margin: 0 auto;
							border-radius: 50%;
							background: $whitesmoke; 
							border: 3px solid $verylightgray;
						}

						.timeline-block{
							width: 370px;
							margin: 0;
							-webkit-transition: 0.5s ease all;
					    	transition: 0.5s ease all;

							.timeline-content{
								background: $white;
								padding: 35px 25px 20px 20px;

								h3{
									color: $venetianred;
									display: block;
									font-size: 26px;
									font-weight: 600;
									letter-spacing: 5px;
									margin: 0 0 5px;
								}

								p{
									font-family: $Montserrat-Light;
									font-weight: 600;
									color: $darkgray;
								}
							}

							&:after{
								content: '';
								position: absolute;
								height: 0;
								border-top: 3px solid $verylightgray;
								width: calc(50% - 380px);
								top: 50%;
							}
						}
					}

					>.timeline-point:nth-child(odd){
						.timeline-block{
							&:after{
						    	left: 370px;
					    	}
						}
					}

					>.timeline-point:nth-child(even){
						.timeline-block{
					    	margin-left: calc(100% - 370px);

					    	&:after{
						    	right: 370px;
					    	}
						}
					}

					>.timeline-point:nth-child(2){
						.timeline-block{
							margin-top: -105px;
						}
					}

					>.timeline-point:nth-child(3){
						.timeline-block{
							margin-top: -75px;
						}
					}
				}

				.timeline:first-of-type{
					padding-top: 105px;
				}

				.timeline:last-of-type{
					.timeline-point{
						.timeline-block{
							&:after{
								top: 48%;
							}
						}
					}

					>.timeline-point:last-child(){
						margin-bottom: 125px;
					}
				}
			}
		}

		&.team-section{
			.page-banner{
				height: 395px;
			}

			.page-header h1{
				padding-top: 150px;
			}

			.container{
				background: $whitesmoke;

				.form-holder{
					background: $white;
					margin: -90px 95px 65px;
					padding: 30px 25px 15px;
					color: $darkgray;
					font-weight: 600;

					.th-form{
						.form-content{
							margin-bottom: 55px;

							h3{
								margin: 0 0 20px;
								font-size: 19.09px;
								font-weight: 600;
							}

							.form-group{
								margin-bottom: 20px;
								
								label{
									font-size: 11.11px;
								}

								input,
								select,
								textarea{
									font-size: 15.28px;
									background: #f8f8f8;
									border-radius: 5px;
									height: 40px;
									border: 1px solid #dedede;
									box-shadow: none;
									width: 100%;
									display: block;
								}

								textarea{
									resize: none;
									height: 125px;
								}
							}

							ul.parsley-errors-list{
								&:empty {
									margin-bottom: 0;
								}
								li{
									font-size: 12px;
									font-weight: normal;
									color: #bd0026;
								}
							}
						}
						.button-holder{
							button{
							display: inline-block;
							margin: 0 auto;
							color: $white;
							background: $gray;
							font-weight: 600;
							font-size: 11.11px;
							font-size: 11.11px;
						    padding: 8px 55px;
						    border-radius: 20px;
							}
						}

						.upload-btn-wrapper {
							position: relative;
							overflow: hidden;
							display: block;
							min-height: 80px;
							
							.btn {
								border: 2px solid #dedede;
								color: #555555;
								background-color: white;
								padding: 8px 20px;
								border-radius: 8px;
								font-weight: bold;
								min-height: 48px;
								max-width: 170px;
								font-size: 15.28px;
							}
							
							input[type=file] {
								position: absolute;
								padding: 48px;
								top: 0;
								left: 0;
								opacity: 0;
								min-height: 48px;
								max-width: 170px;
								cursor: pointer;
							
							}

							.file-input-name {
								color: #555555;
								margin-top: 5px;
							}

							.file-input-name > i {
								color: $venetianred;
								font-size: 25px;
								margin-left: 10px;
							}
						}
					}

					.form-extra{
						font-size: 10.42px;
						color: $gray;
						border-top: 1px solid $gray;
						padding-top: 10px;
						margin-top: 45px;
					}
				}
			}
		}

		&.policy-section{
			.policy-holder{
				font-family: $Montserrat-Light;
				font-size: 16px;
				color: $darkgray;
				padding-bottom: 250px;

				p{
					margin: 0 0 18px;
				}

				a{
					color: $venetianred;
					text-decoration: none;
					font-weight: 600;
				}
				
				strong{
					color: $venetianred;
				}

				h2{
					font-family: $Montserrat-Regular;
					font-size: 33px;
					font-weight: 600;
					color: $venetianred;
					text-transform: uppercase;

					margin-top: 95px;
					margin-bottom: 50px;

					text-align: center;
					letter-spacing: 8px;
				}

				h3{
					font-family: $Montserrat-Regular;
					font-size: 26px;
					font-weight: 600;
					text-transform: uppercase;

					margin-top: 40px;
					margin-bottom: 30px;

					letter-spacing: 6px;
					width: 74%;
				}

				h5{
					font-family: $Montserrat-Regular;
					font-size: 16px;
					font-weight: 600;
					display: inline-block;
					margin-top: 0;
					margin-bottom: 0;
				}
			}
		}

		&.page-section{
			.section-content{
				color: #000;

				.page-content{
					.page-holder{
						padding: 40px 0;

						@media screen and (max-width: 1100px) {
							padding: 40px 15px;
						}

						@media screen and (max-width: 767px) {
							padding: 20px 15px;
						}

						img{
							max-width: 100%;
						}
					}
				}
			}
		}

		.page-banner{
			height: 490px;

			.banner-overlay{
				height: 100%;
				background: rgba(0, 0, 0, 0.3);
				-webkit-transition: all 0.5s ease;
				-o-transition: all 0.5s ease;
				transition: all 0.5s ease;
			}
		}

		.page-header{
			margin: 0;
			padding: 0;
			border-bottom: none;

			h1{
				font-family: $Montserrat-Regular;
				text-transform: uppercase;
				letter-spacing: 4px;
				font-size: 33px;
				font-weight: 600;
				color: $white;
				text-align: center;
				margin: 0;
				padding-top: 210px;
				height: 100%;
			}

			p{
				margin-bottom: 0;
			}
		}
	}
	
	&.th-map {
		.section {
			&.map-section {
				.section-holder {
					display: flex;
					flex-wrap: wrap;
					.search-container {
						width: 400px;
						background: white;

						.search-header {
							text-align: center;
							.header {
								font-family: $Montserrat-Light;
								font-weight: 600;
								font-size: 18px;
								color: $venetianred;
								padding: 30px 10px;
								text-transform: uppercase;
								letter-spacing: 1px;
							}

							.search-box-container {
								.input-group {
									input {
										border-radius: 0px;
										height: 42px;
										font-size: 16px;
										border-color: $venetianred;
									}
									button {
										border-radius: 0px;
										padding: 13px 36px;
										line-height: 16px;
										font-size: 16px;
										font-family: $Montserrat-Light;
										font-weight: 600;
										outline: none;
										letter-spacing: 1px;
										border: 0px;

										&.btn-default {
											background-color: $venetianred;
											color: white;
										}
									}
								}
							}

						}
						.search-list {
							margin-top: 30px;
							//127 search header + 30 for margin top
							max-height: calc(770px - (127px + 30px));
							overflow-y: auto;

							&::-webkit-scrollbar {
							    width: 27px;
							}

							/* Track */
							&::-webkit-scrollbar-track {
							    background: transparent; 
							}
							 
							/* Handle */
							&::-webkit-scrollbar-thumb {
								background: #b4b4b4;
							    border-radius: 27px;
							    border: 10px solid rgba(255, 255, 255, 0);
							    background-clip: padding-box;
							}


							.search-result {
								color: black;
								padding: 20px 10px 20px 35px;
								font-family: $Montserrat-Light;
								font-weight: 600;
								letter-spacing: 1px;
								margin-bottom: 20px;
								transition: background-color .3s;
								cursor: pointer;


								.search-title {
									font-size: 18.04px;

									.coming-soon {
										font-size: 12px;
										color: $venetianred;
										text-transform: uppercase;
									}
								}

								.search-description {
									font-size: 14px;
									margin-bottom: 15px;
									max-width: 290px;
								}
 
								.amenities {
									margin-bottom: 15px;
									.icons {
										margin-right: 5px;
									}
								}

								.directions {
									a {
										text-decoration: underline;
										margin-right: 50px;
									}
								}

								&.active, &:hover {
									color: white;
									background-color: $venetianred;

									.search-title {
										.coming-soon {
											color: white;
										}
									}

									.amenities {
										svg {
											fill: white;
										}
									}
								}
							}

						}
					}
					.map-container {
						height: 770px;
						width: calc(100% - 400px);

						#mapContainer {
							width: 100%;
							height: 100%;
						}
					}
				}
			}	
		}
	}
}

@media screen and (max-width: 1200px) {
	.container{
		width: 100%;
	}
}

@media screen and (max-width: 1000px) {
	section.page{
		.section{
			&.history-section{
				.timeline-holder{
					.timeline{
						margin: 0 15px;
					}
				}
			}
		}
	}	
}

@media screen and (max-width: 991px) {
	footer.footer {
		.container{
			.row{
				.misc-links {
					text-align: center;

					ul{
				    margin-bottom: 20px;

				    li:first-child{
							padding: 0 20px;
						}

						li:last-child{
							padding: 0 20px;
						}
					}
				}
				.copyright {
					text-align: center;
				}
			}
		}
	}
}

@media screen and (max-width: 920px) {
	nav.navbar{
		.container{
			.navbar-collapse{
				ul{
					li{
						ul.dropdown-menu{
							left: 0;
							width: 235px;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 850px) {
	section.page{
		.section{
			&.history-section{
				.timeline-holder{
					margin-top: 25px;
					text-align: left;

					h2{
						margin: 0 15px;
					}

					.timeline{
						&:before{
							margin: 0 58.5px;
						}

						.timeline-point{
							position: relative;
							width: 100%;
							margin-bottom: 35px;

							.timeline-icon{
								margin: 0 50px;
							}

							.timeline-block{
								margin: 0 15px 0 120px;
								width: 80%;

								&:after{
									width: 50px;
									left: 70px;
									right: 0;
								}
							}
						}

						>.timeline-point:nth-child(odd){
							.timeline-block{
								&:after{
									left: 70px;
								}
							}
						}

						>.timeline-point:nth-child(even){
							.timeline-block{
								margin-left: 120px;

								&:after{
									left: 70px;
									right: 0;
								}
							}
						}

						>.timeline-point:nth-child(2){
							.timeline-block{
								margin-top: 0;
							}
						}

						>.timeline-point:nth-child(3){
							.timeline-block{
								margin-top: 0;
							}
						}
					}

					.timeline:first-of-type{
						padding-top: 50px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	nav.navbar{
		.container{
			.navbar-collapse{
				ul {
					margin: 20px 0 10px;
					li {
						a {
							&:hover,
						    &:focus {
					        
						        &:before,
						        &:after {
				            	opacity: 0;
					            -webkit-transform: translateY(0);
				    					-ms-transform: translateY(0);
				        			transform: translateY(0);
						        }
					    	}
						}
						ul.dropdown-menu {
							li {
								padding: 5px 45px 5px 15px;

								&:last-child {
									padding: 5px 45px 20px 15px;
								}
							}
						}

					}
				}
				li.dropdown {
					.caret-holder {
						display: inline-block !important;
						padding: 0px 10px 4px;
					}
				}
				li.dropdown:hover > ul.dropdown-menu{
					display: none !important;
				}
				
				li.dropdown.open > ul.dropdown-menu{
					display: block !important;
					position: static;
					float: none;
					width: auto;
					margin-top: 0;
					background-color: transparent;
					border: 0;
					-webkit-box-shadow: none;
					box-shadow: none;
					padding: 0;
					margin: 0;
				}
			}
			.navbar-header{
				
				a.navbar-brand{
					padding: 24px 15px 24px;
					height: auto;
				}
			}
		}
	}

	footer.footer {
		.misc-links {
			text-align: center;
			margin-bottom: 20px;
		}
		.copyright {
			text-align: center;
		}

		.social-media-footer {
			margin-top: 20px;
			font-size: 16px;
			text-align: center;

			ul {
				margin-bottom: 0px !important;
				li {
					display: inline-block;
					margin-bottom: 0px;
				}
			} 
		}
	}
	.show-mobile {
		display: block !important;
	}
	.hide-mobile {
		display: none;
	}
	section.page {
		.section {
			&.banner-section{
				.banner-container{
					padding: 0;
					border-bottom: 20px solid $white;

					.banner{
						height: auto;
						background-image: none !important;

						.banner-content{
							/*// flex-direction: column-reverse;*/

							.image-holder{
								display: block;							
								/*// img{
								// 	float: initial;
								// 	margin: 0px auto -100px;
								// }*/
							}

							/*// .text-container {
							// 	padding: 120px 0px;

							// 	.text-holder{
							// 		margin: 0 auto;

							// 		h1{
							// 			font-size: 45.51px;
							// 		}
							// 		h2{
							// 			font-size: 40.92px;
							// 		}
							// 		h3{
							// 			font-size: 22.66px;
							// 		}
							// 	}
							// }*/
						}
					}
				}

			}

			&.slider-section {
				margin-bottom: 30px;

				.slider-container {
					.slider-content-container{
						padding: 50px 0px 20px;
						.slider-content {
							flex-wrap: wrap;

							.img-container {
								margin: 0 auto;
							}
						}
					}
					.slider-holder {
						.slider-prev {
							left: 25px;
						}

						.slider-next {
							right: 25px;
						}

					}
				}
			}

			&.history-section{
				.timeline-holder{
					text-align: center;

					h2{
						margin: 0 auto;
					}

					.timeline{
						&:before{
							margin: 0 auto;
						}

						.timeline-point{
							.timeline-icon{
								display: none;
							}

							.timeline-block{
								margin: 0;
								width: 100%;

								&:after{
									display: none;
								}
							}
						}

						>.timeline-point:nth-child(even){
							.timeline-block{
								margin-left: 0;
							}
						}
					}
				}
			}

			&.team-section{
				.container{
					.form-holder{
						margin: 0px 0px 0px;

						.th-form{
							.form-content{
								margin-bottom: 35px;
							}
						}

						.form-extra{
							margin-top: 35px;
						}
					}
				}
			}

			&.policy-section{
				.policy-holder{
					h3{
						width: 100%;
					}
				}
			}

			&.grid-section{
				.grid-accordion {
					.card-header {
						font-size: 18px;

						.card-header-link{
							h3{
								font-size: 18px;
							}
						}
					}
				}
			}
		}
		&.th-map {
			.section {
				&.map-section {
					.section-holder {
						flex-direction: column-reverse;
						.search-container {
							width: 100%;

							.search-list {


								.search-result {

									.search-description {
										max-width: auto;
									}
	 
									.amenities {
										margin-bottom: 15px;
										.icons {
											margin-right: 5px;
										}
									}
									.directions{
										a{
											display: block;
										}
									}

								}

							}
						}
						.map-container {
							width: 100%;
						}
					}
				}	
			}
		}
	}
}

@media screen and (max-width: 600px) {
	footer.footer {
		.container {
			.row {
				padding: 0px 30px;
				.misc-links {
					text-align: center;
				}
				ul {
					li {
						display: block;
						margin-bottom: 10px;
					}
				} 
			}
		}
	} 
}

@media screen and (max-width: 425px) {
	section.page {
		.section {
			&.banner-section{
				.banner-container{

					.banner{

						.banner-content{

							.text-container {

								.text-holder{

									h1{
										font-size: 40.51px;
									}
									h2{
										font-size: 35.92px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.gm-style-iw{
	font-family: $Montserrat-Light !important;
	font-size: 14.03px !important;
	line-height: 20px;
	color: #000;
	
	.iw-content{
		padding: 15px 0 15px 15px;
		@media screen and (max-width: 768px) {
			max-width: 230px;
		}
		.description {
			max-width: 295px;

		}
		h4{
			font-size: 18.04px;
			font-weight: 600 !important;
			margin-bottom: 5px !important;
		}
	}
}

#modalTH{
	.vertical-alignment-helper {
	    display:table;
	    height: 100%;
	    width: 100%;
	    pointer-events:none;
			
			.vertical-align-center {
			    display: table-cell;
			    vertical-align: middle;
			    pointer-events:none;
				
					.modal-content {
						max-width: 400px !important;

				    width:inherit;
				    max-width:inherit; 
				    height:inherit;
				    margin: 0 auto;
				    pointer-events: all;

				    box-shadow: none !important;
				    border-radius: 0;
				    border: 5px solid $venetianred;

				    .modal-header{
				    	border: none;

				    	button{
				    		color: $venetianred;
				    		font-family: $Montserrat-Light;
				    		font-size: 40px;
				    		font-weight: bold;
				    		opacity: 1;
				    	}
				    }

				    .modal-body{
				    	font-family: $Montserrat-Light;
				    	font-size: 25px;
				    	color: #000;
				    	text-align: center;
				    	padding: 40px 25px 85px;
				    }
					}
			}
	}
	
}